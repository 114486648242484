<template>
  <div class="main content-layout-fixer">
    <div class="card-list-wrapper" v-if="hasAgreements">
      <ul>
        <li v-for="t in agreements" :key="t.cardUserId" class="cards">
          <router-link
            class="link"
            :to="{
              name: 'agreement-detail',
              params: { id: t.customerAgreementId },
            }"
          >
            <div class="card">
              <div class="card-container">
                <div class="text-section">
                  <div>
                    <VueText color="grey-30" sizeLevel="3" weightLevel="3">Tarihler</VueText>
                  </div>
                  <div>
                    <VueText color="grey-40" sizeLevel="3" weightLevel="3">{{
                      t.customerAgreementStartDate | parseISODate(t.customerAgreementEndDate)
                    }}</VueText>
                  </div>
                </div>
              </div>
              <div
                class="card-status"
                :class="[
                  t.customerAgreementStatus === 'Started' || t.customerAgreementStatus === 'Opened'
                    ? 'continue'
                    : t.customerAgreementStatus === 'Unknown'
                    ? 'unknown'
                    : 'finish',
                ]"
              >
                <VueText color="white-100" sizeLevel="4" weightLevel="4">{{
                  t.customerAgreementStatus | convertText
                }}</VueText>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="warning-center" v-else>
      <VueText class="warning-card" color="gray-800" sizeLevel="5" weightLevel="2">
        Kriteriniz Bulunmamaktadır.
      </VueText>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import dateUtils from '@/mixins/dateUtils';
import gtmUtils from '@/mixins/gtmUtils.js';
import StorageProps from '@/mixins/storageProps';
import { Agreement } from '@/services/Api/index';

export default {
  name: 'MyAgreementsHome',
  components: {
    VueText,
  },
  mixins: [dateUtils, gtmUtils, StorageProps],
  filters: {
    convertText(text) {
      switch (text) {
        case 'Ended':
          return 'Bitti';
        case 'Started':
          return 'Devam Ediyor';
        case 'Opened':
          return 'Açık';
        case 'Rejected':
          return 'Reddedildi';
        case 'Cancelled':
          return 'İptal Edildi';
        case 'Unknown':
          return 'Bilinmiyor';
        default:
          return 'Bilinmiyor';
      }
    },
  },

  data() {
    return {
      agreements: null,
      hasAgreements: false,
    };
  },
  beforeMount() {
    Agreement.getAgreements().then(res => {
      if (res.data && res.data.Data && res.data.Data.length) {
        this.hasAgreements = true;
        this.agreements = res.data.Data;
        this.agreementId = res.data.Data.customerAgreementId;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_animations.scss';
.main {
  height: 79vh;
  .text-section {
    padding: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.5;
    border-top: 1px solid palette-color-level('grey', 20);
  }
  .card-list-wrapper {
    ul {
      list-style-type: none;
      display: grid;
      grid-template-columns: 32% 32% 32%;
      width: 100%;
    }
    padding: palette-space-level('10');
    margin-bottom: palette-space-level('10');
    display: flex;
    flex-direction: column;

    .header {
      padding-bottom: palette-space-level('25');
      padding-top: palette-space-level('25');
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filter-button {
        border-radius: palette-radius-level('2');
        padding: palette-space-level('10');
        box-shadow: 0 0 palette-space-level('10') 0 rgba(0, 0, 0, 0.15);
      }
    }
    li {
      box-shadow: 0 0 palette-space-level('10') 0 rgba(0, 0, 0, 0.2);
      margin: 8px;

      .card {
        .card-container {
          .title {
            width: 100%;
            height: 100%;
            .card-name {
              border-top: 1px solid rgba(180, 194, 211, 0.5);
              padding: palette-space-level(10);
            }
          }
        }
        .card-status {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: palette-space-level(3);
          p {
            padding-left: palette-space-level(5);
          }
        }
      }
    }
  }
  .continue {
    @include bg-linear-gradient(
      86deg,
      palette-color-level('green', '20'),
      palette-color-level('green', '30')
    );
  }
  .finish {
    @include bg-linear-gradient(
      to left,
      palette-color-level('red', '40'),
      palette-color-level('red', '30')
    );
  }
  .unknown {
    background-color: #c6c6c6;
  }

  .link {
    text-decoration: none;
  }

  .warning-center {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;
    .warning-card {
      padding: palette-space-level('30');
      box-shadow: 0 0 palette-space-level('10') 0 rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
